<template>
  <section class="py-8 bg-white">
    <div class="container px-4 mx-auto">
      <button @click="showAddApplicationModal = true"
        class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Applikation
        hinzufügen</button>
      <div class="overflow-x-auto mb-16">
        <div class="relative inline-block min-w-full border border-gray-100 rounded-5xl overflow-hidden">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr class="text-sm uppercase font-heading text-left">
                <th class="flex items-center pl-10 py-6 pr-12 md:pr-40 lg:pr-52 2xl:pr-64">
                  <span>Name</span>
                </th>
                <th class="py-4 pr-16"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="application in applications" :key="application._id">
                <td class="p-0">
                  <div class="flex items-center pl-10 pr-4 h-20 border-b border-gray-100">
                    <div class="flex">
                      <div>
                        <p class="font-heading font-medium">{{ application.name }}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                  <div class="flex items-center pl-10 pr-4 h-20 border-b border-gray-100">
                    <div class="flex">
                      <div>
                        <button @click="openEditApplicationModal(application._id)" class="font-heading font-medium">Bearbeiten</button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>



  <div>
    <vue-final-modal v-model="showAddApplicationModal" classes="modal-container" content-class="modal-content"
      :esc-to-close="true" :lock-scroll="false" @closed="onModalClosed()">
      <form @submit="addApplication()">
        <div class="max-w-md lg:mx-auto">
          <form action="">
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Name (Erforderlich)" v-model="addApplicationName">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Icon (Empfohlen)" v-model="addApplicationIcon">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Datei (Erforderlich) z.B. v2.28.zip" v-model="addApplicationFile">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Pfad (Erforderlich) z.B. Release\ClubInOne.exe" v-model="addApplicationPath">
            </div>
            Weitere Dateien:
            <div v-for="(file, index) in addApplicationAdditionalFiles" :key="index">
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Datei (Erforderlich) z.B. Settings.ini" v-model="file.fileName">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Pfad (Erforderlich) z.B. Release\Settings.ini" v-model="file.filePath">
            </div>
            ---
            </div>
            <div class="mb-4">
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="addAdditionalFile()"
                class="mb-4 w-full py-1 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Weitere
                Datei hinzufügen</button>
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="deleteAdditionalFile()"
                class="mb-4 w-full py-1 bg-red-600 hover:bg-red-700 rounded text-sm font-bold text-gray-50">Letzte
                Datei löschen</button>
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="addApplication()"
                class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Applikation
                hinzufügen (Speichern)</button>
            </div>
            <p v-if="addApplicationShowSuccess"
              class="mb-4 w-full py-2 bg-green-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ addApplicationSuccessMessage }}</p>
            <p v-if="addApplicationShowError"
              class="mb-4 w-full py-2 bg-red-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ addApplicationErrorMessage }}</p>
          </form>

        </div>
      </form>
    </vue-final-modal>
  </div>




    <div>
    <vue-final-modal v-model="showEditApplicationModal" classes="modal-container" content-class="modal-content"
      :esc-to-close="true" :lock-scroll="false" @closed="onModalClosed()">
      <form @submit="editApplication()">
        <div class="max-w-md lg:mx-auto">
          <form action="">
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Name (Erforderlich)" v-model="editApplicationName">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Icon (Empfohlen)" v-model="editApplicationIcon">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Datei (Erforderlich) z.B. v2.28.zip" v-model="editApplicationFile">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Pfad (Erforderlich) z.B. Release\ClubInOne.exe" v-model="editApplicationPath">
            </div>
            Weitere Dateien:
            <div v-for="(file, index) in editApplicationAdditionalFiles" :key="index">
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Datei (Erforderlich) z.B. Settings.ini" v-model="file.fileName">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Pfad (Erforderlich) z.B. Release\Settings.ini" v-model="file.filePath">
            </div>
            ---
            </div>
            <div class="mb-4">
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="addAdditionalFile('edit')"
                class="mb-4 w-full py-1 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Weitere
                Datei hinzufügen</button>
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="deleteAdditionalFile('edit')"
                class="mb-4 w-full py-1 bg-red-600 hover:bg-red-700 rounded text-sm font-bold text-gray-50">Letzte
                Datei löschen</button>
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="editApplication()"
                class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Applikation
                hinzufügen (Speichern)</button>
            </div>
                                 <div class="flex justify-between items-center text-center">

                             <button @click="deleteApplication()"
                class="mb-4 w-full py-4 bg-red-600 hover:bg-red-700 rounded text-sm font-bold text-gray-50">Applikation
                löschen</button></div>
            <p v-if="editApplicationShowSuccess"
              class="mb-4 w-full py-2 bg-green-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ editApplicationSuccessMessage }}</p>
            <p v-if="addApplicationShowError"
              class="mb-4 w-full py-2 bg-red-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ editApplicationErrorMessage }}</p>
          </form>

        </div>
      </form>
    </vue-final-modal>
  </div>
</template>

<script>
  import axios from 'axios';
  //import copy from 'copy-to-clipboard';

  export default {
    name: 'Applications',
    data() {
      return {
        test1: undefined,
        test2: undefined,
        applications: undefined,
        showAddApplicationModal: false,

        addApplicationName: undefined,
        addApplicationIcon: undefined,
        addApplicationFile: undefined,
        addApplicationPath: undefined,
        addApplicationSuccessMessage: undefined,
        addApplicationShowSuccess: false,
        addApplicationErrorMessage: undefined,
        addApplicationShowError: false,
        addApplicationAdditionalFiles: [],

        editApplicationName: undefined,
        editApplicationIcon: undefined,
        editApplicationFile: undefined,
        editApplicationPath: undefined,
        editApplicationSuccessMessage: undefined,
        editApplicationShowSuccess: false,
        editApplicationErrorMessage: undefined,
        editApplicationShowError: false,
        editApplicationAdditionalFiles: [],

        applicationToEdit: undefined,
        showEditApplicationModal: false,
      }
    },
    mounted() {
      this.getApplications()
    },
    methods: {
      addAdditionalFile(mode) {
        if (mode === "edit") {
        this.editApplicationAdditionalFiles.push({
          fileName: "",
          filePath: "",
        })
        } else {
        this.addApplicationAdditionalFiles.push({
          fileName: "",
          filePath: "",
        })
        }

      },
      deleteAdditionalFile(mode) {
        if (mode === "edit") {
          this.editApplicationAdditionalFiles.pop()
        } else {
          this.addApplicationAdditionalFiles.pop()
       }
        
      },
      onModalClosed() {
        console.log('asdf')
      },
      openEditApplicationModal(applicationId) {
        const application = this.applications.find(element => element._id == applicationId);

        this.editApplicationName = application.name
        this.editApplicationIcon = application.icon
        this.editApplicationFile = application.remoteAppApplicationFileName
        this.editApplicationPath = application.remoteAppApplicationPath
        this.editApplicationAdditionalFiles = application.additionalFiles,

        this.editApplicationShowError = false
        this.editApplicationErrorMessage = undefined

        this.editApplicationShowSuccess = false
        this.editApplicationSuccessMessage = undefined

        this.applicationToEdit = application._id
        this.showEditApplicationModal = true
      },
      getApplications() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/applications/`)
        .then(response => {
          this.applications = response.data
        })
        .catch(error => {
          console.log('error')
          console.log(error)

        })
      },
      async addApplication() {
        axios
          .post(`${process.env.VUE_APP_BACKEND_URL}/applications/`, {
            "name": this.addApplicationName,
            "icon": typeof this.addApplicationIcon === "string" && this.addApplicationIcon.length === 0 ? undefined : this.addApplicationIcon,
            "remoteAppApplicationFileName": this.addApplicationFile,
            "remoteAppApplicationPath": this.addApplicationPath,
            "additionalFiles": this.addApplicationAdditionalFiles,
          })
          .then(() => {
            this.addApplicationShowError = false
            this.addApplicationErrorMessage = false

            this.addApplicationSuccessMessage = 'Applikation erfolgreich hinzugefügt'
            this.addApplicationShowSuccess = true

            this.addApplicationName = undefined
            this.addApplicationIcon = undefined
            this.addApplicationFile = undefined
            this.addApplicationPath = undefined
            this.addApplicationAdditionalFiles = []

            this.getApplications()
          })
          .catch(error => {
            this.addApplicationErrorMessage = error.response.data.message
            this.addApplicationShowError = true
            console.log(error.response)
          })
      },
      async editApplication() {
        axios
          .put(`${process.env.VUE_APP_BACKEND_URL}/applications/${this.applicationToEdit}`, {
            "name": this.editApplicationName,
            "icon": typeof this.editApplicationIcon === "string" && this.editApplicationIcon.length === 0 ? undefined : this.editApplicationIcon,
            "remoteAppApplicationFileName": this.editApplicationFile,
            "remoteAppApplicationPath": this.editApplicationPath,
            "additionalFiles": this.editApplicationAdditionalFiles,
          })
          .then(() => {
            this.editApplicationShowError = false
            this.editApplicationErrorMessage = false

            this.editApplicationSuccessMessage = 'Applikation erfolgreich geändert'
            this.editApplicationShowSuccess = true

            this.editApplicationName = undefined
            this.editApplicationIcon = undefined
            this.editApplicationFile = undefined
            this.editApplicationPath = undefined
            this.editApplicationAdditionalFiles = []

            this.getApplications()
          })
          .catch(error => {
            this.editApplicationErrorMessage = error.response.data.message
            this.editApplicationShowError = true
            console.log(error.response)
          })
      },
      async deleteApplication() {
        axios.delete(`${process.env.VUE_APP_BACKEND_URL}/applications/${this.applicationToEdit}`)
        .then(() => {
            this.editApplicationShowError = false
            this.editApplicationErrorMessage = false

            this.editApplicationSuccessMessage = 'Applikation erfolgreich gelöscht'
            this.editApplicationShowSuccess = true

            this.editApplicationName = undefined
            this.editApplicationIcon = undefined
            this.editApplicationFile = undefined
            this.editApplicationPath = undefined
            this.editApplicationAdditionalFiles = []

            this.getApplications()
        })
        .catch(error => {
            this.editApplicationErrorMessage = error.response.data.message
            this.editApplicationShowError = true
            console.log(error.response)
        })
      },
    },
  }
</script>

<style scoped>
  ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .modal-content {
    position: relative;
    width: fit-content;
    min-width: 30%;
    padding: 16px;
    overflow: auto;
    background-color: #f9fafb;
    border-radius: 4px;
  }

  .modal__title {
    font-size: 1.5rem;
    font-weight: 700;
  }
</style>

<style scoped>
  .dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
  }
</style>